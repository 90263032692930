import { useSwiper } from 'swiper/react'
import styled from 'styled-components/macro'
import { ReactComponent as ArrowLeft  } from './arrow-left.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { getFocus, useGet }     from 'state/jotai'
import { active_project }   from 'state/store.global'
import { _project }                      from 'state/store.projects'

export const SwiperNav = ({activeIndex, images}) => {

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const swiperHook = useSwiper()
  const _onClickPrev = () => { swiperHook.slidePrev() }
  const _onClickNext = () => { swiperHook.slideNext() }

  return (
    <>
      <Nav>
        { 
          ![0, null].includes(activeIndex) ? 
          ( <Btn onClick={_onClickPrev}><Left/></Btn> ) : 
          ( <Btn style={{ visibility: 'hidden' }}><Left/></Btn> )
        }
        <Pag>{ `${activeIndex+1} ${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'udAf')[0]?.Label } ${images.length}` }</Pag>
        { 
          activeIndex !== images.length - 1 ? 
          ( <Btn onClick={_onClickNext}><Right/></Btn> ) : 
          ( <Btn style={{ visibility: 'hidden' }}><Right/></Btn> )
        }
      </Nav>
    </>
  )
}


const Left  = styled(ArrowLeft)`width:10px;margin-right:15px;`
const Right = styled(ArrowRight)`width:10px;margin-left:15px;`

const Pag = styled.div`
  width:150px;
  padding:10px;
  display:flex;
  justify-content:center;
  color:white;
  gap:5px;
  user-select:none;
  font-size:0.9375rem;
  /* border:1px solid white; */
`
const Nav = styled.div`
  position:fixed;
  bottom:0px;
  left:50%;
  transform:translatex(-50%);
  display:flex;
  padding:10px 0 5px 0;
  gap:10px;
  z-index:1;
  border-radius:19px 19px 0 0;
  background:#000000f2;
`
const Btn = styled.button`
  border:0;
  user-select:none;
  background:#000;
  padding:10px 20px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:19px;
  cursor:pointer;
  color:white;
`