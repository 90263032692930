import styled, {css} from 'styled-components'

export const Button = ({children,click,mode=1,fontSize='1.25rem',fontWeight=600}) => {
  return (
    <StyledButton clr={clr} onClick={click} mode={mode} fontSize={fontSize} fontWeight={fontWeight}>
      { children }
    </StyledButton>
  )
}

const StyledButton = styled.button(
  ({ mode,fontSize,fontWeight }) => css`
    background-color: ${mode ? clr?.buttons : 'transparent'};
    color: ${ mode ? clr?.buttons_text : clr?.sub_text_color };
    padding: 10px 20px;
    cursor: pointer;
    border: ${mode ? '0px solid black' : `1px solid ${clr?.sub_text_color}`};
    text-align: center;
    font-weight:${fontWeight};
    font-size:${fontSize};
    width: fit-content;
    white-space:nowrap;
  `
)