import { Box, Button } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import { _project } from 'state/store.projects'
import { getProjectData } from 'state/jotai.projectDataHooks'
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _bootstrap } from "state/store.bootstrap";

function Downloads() {
  const downloads = getProjectData('downloads',0,[])
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const sitename  = useGet(_bootstrap).sitename;

  if (downloads.length === 0) {
    return null; // If downloads array is empty
  }
  return (
    <Box
      sx={{
        mt: "45px",
      }}
    >
      <Box sx={{ fontWeight: 700, mb: "18px" }}>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'HentMateriale')[0]?.Label }</Box>
      {downloads.map((item, i) => {
        return (
          <Box key={i}>
            <Button
              variant="text"
              href={item?.url}
              target="_blank"
              startIcon={<Icon icon="download" />}
            >
              {/* <span>{ buttonsLabel?.filter( btn => btn.UILabelProperty === item?.Text)[0]?.Label }</span> */}
              <span>{ buttonsLabel?.filter( btn => btn.UILabelProperty === item.property)[0]?.Label} - {sitename}</span>
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}

export default Downloads;
