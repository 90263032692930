import Lottie from "lottie-react";
import mediaLoader from "/src/assets/animation-json/media-loader.json";

export const LottiPlay = ({animateJSON, ...props}) => {
    return <Lottie style={{ ...props.style }} animationData={animateJSON} loop={true} />
}

// default props
LottiPlay.defaultProps = {
    animateJSON: mediaLoader
};
 