import { useEffect }    from 'react'
import { useSnapLogic } from 'components/Isometry/Isometry.SnapLogic'
import { useSet }       from 'state/jotai'
import { vis_overlay }  from 'state/store.global'
import { TWEENS }       from 'components/Isometry/Isometry.utilities'

export const SnapOnRotationEnd = ({ frame, setFrame, config, setIndex, rmode }) => {
  const SNP = config?.IMAGE_SNAPFRAMES
  const INI = config?.FIRST_IMAGE
  const TWN = SNP?.length > 1 ? SNP?.slice(1) : SNP
  const tweens = TWEENS(TWN, INI, SNP) ?? []
  const setShow = useSet(vis_overlay)
  
  useEffect(()=>{
    if ( !rmode ) {
      useSnapLogic({ value: frame, setFrame, tweens, setShow, setIndex, SNP })
    }
  },[rmode,SNP])

  return null
}