import { Box } from "@mui/material";
import { UnitPage } from "components/Unit";
import useUnitPage from "components/Unit/useUnitPage";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";

export const ViewUnit = () => {
  const UnitID = useParams().UnitID;

  const containerRef = useRef(null);

  const [, setRef] = useUnitPage();

  useEffect(() => {
    setRef(containerRef.current);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        // py: { md: 0, xs: "59px" },
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <UnitPage unitId={parseInt(UnitID)} />
    </Box>
  );
};
