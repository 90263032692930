import { Box, Grid } from "@mui/material";
import UnitCard from "components/Unit/UnitCard";
import useUnitDrawer from "components/Unit/useUnitDrawer";
import { useAtom } from "jotai";
import React from "react";
import { active_project, fav_grid } from "state/store.global";
import { useFavoriteUnits } from "./useFavoriteUnits";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
// FAVORITES
import ToolbarFavorites from "components/Favorites/Toolbar";
import { useBreak } from "hooks/useBreak";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

const Favorites = () => {
  const { units: favUnits } = useFavoriteUnits();
  const [isGrid] = useAtom(fav_grid);
  const { open } = useUnitDrawer();
  const isDesktop = useBreak("md_up");

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const handleClickCard = (unit) => {
    open(unit);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isDesktop && <ToolbarFavorites favUnits={favUnits} />}
      {!isDesktop && (
        <>
          <Box sx={{ px: 3, my: 3 }}>
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Yndlingsoverskrift1"
              )[0]?.Label
            }
            <strong>
              {` ${favUnits?.length} `}
              {favUnits.length > 1
                ? ` ${
                    buttonsLabel?.filter(
                      (btn) => btn.UILabelProperty === "boliger"
                    )[0]?.Label
                  } `
                : ` ${
                    buttonsLabel?.filter(
                      (btn) => btn.UILabelProperty === "bolig"
                    )[0]?.Label
                  } `}
            </strong>
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Yndlingsoverskrift2"
              )[0]?.Label
            }
          </Box>
        </>
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: {
          md:'calc(100vh - 187px)', 
          xs:'calc(100vh - 250px)'
        },
        justifyContent: 'space-between',
        }}>
      <Box sx={{ padding: "0 20px" }}>
        <Grid container spacing={2}>
          {favUnits.map((unit) => {
            return (
              <Grid
                item
                key={unit.getId()}
                xs={12}
                sm={isGrid ? 6 : 12}
                md={isGrid ? 4 : 12}
              >
                <UnitCard
                  unit={unit}
                  layout={isGrid ? "grid" : "list"}
                  onClick={handleClickCard}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <PoweredBy/>
      </Box>
    </Box>
  );
};

Favorites.propTypes = {};

export default Favorites;
