import { useAtom, getFocus, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { active_project, current_language, isMulti_project_active, iso_free, vis_filter, vis_desktop_nav, iframeMode } from "state/store.global";
import { close_drawer } from "state/actions";

import { Icon } from "assets/Icons_";

import { Box, FullWidth, Section } from "./DualMenu.style";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";
// import { options } from './../Dropdown2/Dropdown2.Data';

export const DualMenu = ({ left, right, nav }) => {
  const getIframeMode = useAtom(iframeMode)[0];
  const isDesktop = useBreak("md_up");
  const navigate = useNavigate();
  const location1 = useLocation().pathname.split("/")[1];
  const names = getFocus(_bootstrap, "projectnames");
  const currentLanguage = useAtom(current_language)[0];

  const here = useLocation().pathname;
  const type = getFocus(_bootstrap, "type");
  const rmode = useAtom(iso_free)[0];
  const isFilter = useAtom(vis_filter)[0];
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const [, setShowFilter] = useAtom(vis_filter);
  const closeDrawer = useAtom(close_drawer)[1];
  const setMultiActive = useAtom(isMulti_project_active)[1];

  const {
    i18n: { language },
  } = useTranslation();

  const clickList = () => {
    setShowFilter(false);
    setShowMenu(false);
    if (pin > 0) setMultiActive(true);
    else setMultiActive(false);
    navigate(type == "mono" ? `/${language}/list` : `${language}/list`);
  };

  const clickModel = () =>{
    closeDrawer("detail");
    setShowFilter(false);
    setShowMenu(false);
    navigate(
      type == "mono"
        ? `/${language}/isometry`
        : `/${language + names[currentLanguage][pin].path}`
    )};

  const list = {
    size: 22,
    icon: "list",
    color: here.includes("list") ? "#ffffff" : "#aaaaaa",
  };
  const model = {
    size: 28,
    icon: "model",
    color: here.includes("isometry") ? "#ffffff" : "#aaaaaa",
  };

  return (
    <>
      {!(atUnit && isDesktop) && (
        <FullWidth left={left} right={right} nav={nav} frameMode={getIframeMode}>
          <Box clr={clr}>
            <Section
              clr={clr}
              active={here.includes("isometry")}
              onClick={clickModel}
            >
              <Icon {...model} />
              Model
            </Section>
            <Section
              clr={clr}
              active={here.includes("list")}
              onClick={clickList}
            >
              <Icon {...list} />
              {
                buttonsLabel?.filter(
                  (btn) => btn.UILabelProperty === "Boligliste"
                )[0]?.Label
              }
            </Section>
          </Box>
        </FullWidth>
      )}
    </>
  );
};
