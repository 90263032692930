import { active_project, iframeMode }         from 'state/store.global'
import { vis_filter, vis_detail } from 'state/store.global'
import { vis_desktop_nav }        from 'state/store.global'
import { useAtom, useGet }                 from 'state/jotai'
import { Content, Root }          from './Layout.style'
import { DualMenu }               from 'components/DualMenu/DualMenu'
import { TopMenu }                from 'components/TopMenu/TopMenu'
import { useLocation }            from 'react-router-dom'
import { useBreak }               from 'hooks/useBreak'

export const Layout = ({ children }) => {
  
  const here = useLocation().pathname
  const isDesktop    = useBreak('md_up')
  const getIframeMode = useAtom(iframeMode)[0];
  
  const { Navigation, DrawersLeft, DrawersRight, Views } = children
  
  const pin   = useGet(active_project).pin
  const left  = useGet(vis_filter)
  const right = useGet(vis_detail)
  const nav   = useGet(vis_desktop_nav)
  const marginChange = ["compare", "unit", "isometry", "gallery", "downloads"].some(i => here.includes(i)) && !isDesktop
  const heightChange = ["list"].some(i => here.includes(i)) && !isDesktop
  return (
    <Root>
      { pin >= 0 && DrawersLeft.map(i => i) }
      { pin >= 0 && DrawersRight.map(i => i) }
      <Content left={left} right={right} nav={nav} heightChange={marginChange} iframeMode={getIframeMode} mobileHeight={heightChange} styles={{ marginTop: marginChange ? (getIframeMode ? "0" : "59px") : (getIframeMode ? "59px" : "119px")}}>
        { Views }
      </Content>
      <TopMenu left={left} right={right} nav={nav}/>
      <DualMenu left={left} right={right} nav={nav}/>
      { !getIframeMode && Navigation }
    </Root>
  )
}