import React from "react";
import { useMount } from "hooks/useMount";
import { Layout } from "layout/Layout";
import { Views } from "pages/Views";
import { Loading2 } from "components/Loading2/Loading2";
import { Nav } from "components/Nav/Nav";
import { Drawer } from "components/Drawer/Drawer";
import { Portals } from "portals/Portals";
import { useGet } from "state/jotai";
import { renderLayout } from "state/store.global";
import AppThemeProvider from "theme/ThemeProvider";
import ContactDrawer from "components/Contact/ContactDrawer";
// import { useLog } from 'logging/logging'

export const App = () => {

  // const loga = useLog()

  useMount(() => {
    log({ action: "app_opened" });
  });

  const shouldRender = useGet(renderLayout);

  if (shouldRender)
    return (
      <>
        <AppThemeProvider>
          <Layout>
            {{
              Navigation: <Nav />,
              Views: <Views />,
              DrawersLeft: [<Drawer key="1" w="431" filter />],
              DrawersRight: [<Drawer key="2" w="422" detail />],
            }}
          </Layout>
          <Portals />
          <ContactDrawer />
        </AppThemeProvider>
      </>
    );

  return <Loading2 />;
};
// App.whyDidYouRender = true
