import eachOfLimit                from 'async/eachOfLimit'
import { useEffect }              from 'react'
import { useFocusSet, useGet }    from 'state/jotai'
import { useFetch }               from 'hooks/fetch/useFetch'
import { _isometry }              from 'state/store.isometry'
import { _bootstrap }             from 'state/store.bootstrap'
import { array }                  from 'utilities/utility.misc'
import { preloadImage }           from 'utilities/utility.images'
import { ISO_FRAMES, ISO_CONFIG } from 'api/api'

export const PreloadFrames_Mono = () => {

  const type   = useGet(_bootstrap).type
  const file   = 'main/Mono/MonoPreload.jsx'
  const mono   = type === 'mono'
  const url    = mono ? ISO_CONFIG : null
  const config = useFetch({ url, file }).D

  const setFrames  = useFocusSet(_isometry, 'frames')
  const setPercent = useFocusSet(_isometry, 'percent')
  const setStatus  = useFocusSet(_isometry, 'status')
  const setConfig  = useFocusSet(_isometry, 'config')

  useEffect(()=>{
    // if ( config && mono ) {
    //   const i = config[0]
    //   const PID = i.PROJECT_ID
    //   const NUM = i.IMAGE_NUM
    //   const INI = i.FIRST_IMAGE
    //   const EXT = i.FILE_EXT
    //   const SUB = i.PNG_PATH
    //   // const FNC = x => `${ISO_FRAMES}/${PID}/${x + INI}.${EXT}`
    //   const FNC = x => `${ISO_FRAMES}${SUB}/${x + INI}.${EXT}`
    //   const COL = array(NUM, FNC)
    //   // console.log( COL )
    //   const ARR = []
    //   setConfig( [ { [PID] : i } ] )
    //   eachOfLimit(COL, 20,
    //     ( val, idx, callback ) => {
    //       const IMG      = preloadImage(val)
    //       const STATUS   = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
    //       const PERCENT  = ( idx * ( 100 / NUM ) ).toFixed()
    //       ARR[idx] = { [INI+idx]:IMG }
    //       setPercent( [ { [PID] : PERCENT } ] )
    //       setFrames ( [ { [PID] : ARR     } ] )
    //       setStatus ( [ { [PID] : STATUS  } ] )
    //       IMG.onload = ( ) => callback()
    //     },
    //     () => {
    //       // console.log( 'isometries for mono project eido finished' )
    //     }
    //   )
    // }
  },[mono, config])

  return null
}
// Preload.whyDidYouRender = true