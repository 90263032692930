import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { _bootstrap }     from 'state/store.bootstrap'
import { active_project, compares }       from 'state/store.global'
import { comparesData }   from 'state/store.global'
import { useGet, useSet } from 'state/jotai'
import { COMPARE }        from 'api/api'

export const PreloadCompares = () => {
  
  const comps   = useGet(compares).join(',')
  // const pid     = useGet(_bootstrap).projectids?.[0] || null
  const setData = useSet(comparesData)
  const { pin, pid }  = useGet(active_project);

  const { D } = useFetch({ url:COMPARE(pid,comps), file:'PreloadCompares.jsx'})

  useEffect(()=>{
    if ( D ) {
      setData( D )
    }
  },[D,comps])

  return null
}
