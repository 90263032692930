import { animate } from 'framer-motion'

export const useSnapLogic = ({
  value,
  setFrame,
  tweens,
  setShow,
  setIndex,
  SNP,
}) => {
  // Determine within which tween the current stop frame is
  const SECT = tweens?.findIndex(i => i.includes(value))
  const TWEEN = tweens?.[SECT]
  
  // Find center of tween
  const CEN = TWEEN?.[Math.floor(TWEEN?.length / 2 || 0)]
  
  // Determine whether snapping should go up or down
  const DIR = value > CEN ? 'UP' : 'DOWN'
  
  // Determine the frame of snapping
  const FRAME = DIR === 'UP' ? TWEEN?.[TWEEN?.length - 1 || 0] : TWEEN?.[0]

  // clog( 'usesnaplogic, index: ' , SNP?.indexOf(FRAME))

  // Set the snap, show and index
  setIndex(SNP?.indexOf(FRAME))
  setShow(true)
  animate(value, FRAME, {
    duration: 0.5,
    onUpdate: v => setFrame(+v.toFixed(0)),
  })
}