import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import DownloadItem from './DownloadItem'
import Filter from './Filter'
import useDownloads from './useDownloads'
import { _project } from 'state/store.projects'
import { getProjectData } from 'state/jotai.projectDataHooks'
import { getFocus, useGet } from 'state/jotai'
import { active_project } from 'state/store.global'
import { PoweredBy } from 'components/PoweredBy/PoweredBy'

function Downloads() {
  const data = getProjectData('downloads',0,[])

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState('all')
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState(data)

  const handleFilter = selected => {
    setType(selected)

    // TODO: handle selected filter.
    setFilteredData(data)
  }

  return (
    <Box
      sx={{
        pt: { lg: '4.625rem', xs: '2.5rem' },
        lineHeight: 1.4,
      }}
    >
      <Box
        sx={{
          px: { lg: '2rem', xs: '1.25rem' },
          mb: { lg: '3.5rem', xs: '1.875rem' },
          maxWidth: '528px',
        }}
      >
        <Typography
          component='h2'
          variant='h2'
          sx={{
            fontWeight: 700,
            fontSize: { lg: '1.375rem', xs: '1.125rem' },
            mb: { lg: '1.875rem', xs: '1rem' },
          }}
        >
         { buttonsLabel?.filter( btn => btn.UILabelProperty === 'DownloadMateriale')[0]?.Label }
        </Typography>
        <p>
          { buttonsLabel?.filter( btn => btn.UILabelProperty === 'DownloadUndertekst')[0]?.Label }
        </p>
      </Box>

      {/* <Box sx={{ px: { lg: "2rem", xs: "1.25rem" }, mb: "0.5rem" }}>
        <Filter onSelect={handleFilter} />
      </Box> */}
      <Box sx={{display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 275px)', justifyContent: 'space-between'}}>
      <Box>
        {data?.map((item, i) => (
          <DownloadItem key={i} {...item} />
        ))}
      </Box>
      <PoweredBy/>
      </Box>
    </Box>
  )
}

export default Downloads
