import styled from 'styled-components/macro'

export const Container = styled.div`
  max-width: 100vw;
  width: 700px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Inner = styled.div`
  padding: 20px 60px 30px 40px;
  h2 {
    font-size: 18px;
    margin: 10;
  }
  h3 {
    font-size: 16px;
    margin: 10;
  }
  p {
    font-size: 17px;
    line-height: 24px;
  }
`

export const Input = styled.input`
  max-width: 350px;
  height: 44px;
  background: #f1f0f0;
  border: 1px solid #cfd5d2;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 15px;
`
