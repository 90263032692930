import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom"
import { currentActiveProject, current_language } from "state/store.global";

export const useClick = () => {
    const currentLanguage = useAtom(current_language)[0];
    const setActiveProject = useAtom(currentActiveProject)[1];
    const navigate = useNavigate()

  const click = e => {
    if ( typeof e?.Presentation === 'string' ) {
        setActiveProject(e?.Presentation);
        navigate(`/${currentLanguage}/isometry/${e?.Presentation}`)
    }
  }

  return click
}