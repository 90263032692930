import { useDetectClickOutside }    from 'react-detect-click-outside'
import { Icon }                     from 'assets/Icons_'
import { Container, Section, Mode } from './List.Caption.style'
import { _project }                 from 'state/store.projects'
import { getFocus, useGet, useSet }              from 'state/jotai'
import { active_project, vis_sorter }               from 'state/store.global'
import { useBreak }                 from 'hooks/useBreak'
import { Sort }                     from 'components/Sort2/Sort'

export const Caption = ({count,mode,setMode,total}) => {

  const isDesktop = useBreak('md_up')
  const setState  = useSet(vis_sorter)
  const set       = (mode) => { return () => setMode(mode) }
  const outside   = { onTriggered: () => setState(false) }
  const ref       = useDetectClickOutside(outside)
  const onClick   = () => setState(a => !a)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const x = [
    // { name:'table', ico:'caption_icon_list', sz:20, clr:mode=='table'?'#000':'#aaa' },
    { name:'card' , ico:'three_bars'       , sz:25, clr:mode=='card' ?'#000':'#aaa' },
    { name:'grid' , ico:'caption_icon_grid', sz:21, clr:mode=='grid' ?'#000':'#aaa' },
  ]

  return (
    <Container>
      <Section left>
        { x.map(({ name, ico, sz, clr })=> (
          <Mode onClick={set(name)} mode={mode} name={name} key={name}>
            <Icon icon={ico} color={clr} size={sz}/>
          </Mode>
        ))}
      </Section>
      <Section mid>
        {/* { count ? `${count} / ${total}` : null } */}
        <span style={{ whiteSpace: !isDesktop ? 'nowrap' : '' }}>
        { count ? `${count} ${ buttonsLabel.filter( btn => btn.UILabelProperty === 'visteEnheder')[0]?.Label }` : null }
        </span>
      </Section>
      <Section right>
       {isDesktop && <Sort />}
        {/* <Sorter onClick={onClick} ref={ref}>
          Sorter: {!br && activesort.label} <Sort/>
        </Sorter> */}
      </Section>
    </Container>
  )
}