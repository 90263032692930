import { getFocus, setFocus } from 'state/jotai'
import { Label, Name, Sub }   from './Overview.Labels.style'
import { useLocation } from 'react-router-dom'
import { useBreak } from 'hooks/useBreak'

const check = (x) => isFinite(1/x) && !isNaN(1/x)

export const Labels = ({ frame, index, config, rmode, scale }) => {

  const here = useLocation().pathname.split('/')
  const SNP = config.IMAGE_SNAPFRAMES 

  const isOverview = here[here.length-1] == 'isometry'

  const isDesktop = useBreak('md_up')
  
  const L = [
    { L1:[1220, 300], L2:[100, 700] , C1:[1000, 400], C2:[1000, 400], O1:'bottom-left', O2:'top-right'},
    { L1:[1000,620] , L2:[0, 289]   , C1:[1000, 400], C2:[1000, 400], O1:'bottom-left', O2:'top-right'},
    { L1:[15, 200]  , L2:[1500, 600], C1:[1000, 400], C2:[1000, 400], O1:'bottom-left', O2:'top-right'},
    { L1:[700, 0]   , L2:[1200, 900], C1:[1000, 400], C2:[1000, 400], O1:'bottom-left', O2:'top-right'},
    { L1:[1000,20]  , L2:[100, 820] , C1:[1000, 400], C2:[1000, 400], O1:'bottom-left', O2:'top-right'},
  ]
  
  const A = L?.[index === undefined || index.length == 0 || index == false ? 0 : index]

  const data = [
    { DISABLED:false, MAIN:'North', ORIGIN:A?.['O1'], C1:A?.['C1'], C2:A?.['L1'], SUB1:'text1', SUB2:'text2' },
    { DISABLED:false, MAIN:'South', ORIGIN:A?.['O2'], C1:A?.['C1'], C2:A?.['L2'], SUB1:'text1', SUB2:'text2' },
  ]

  const s    = check(scale) ? 1/scale : 1 || 1
  const lab  = {padding:`${10*s}px ${60*s}px`,borderRadius:`${0*s}px`}
  const line = (DISABLED) => ({stroke:DISABLED?'#000':'#000', strokeWidth:2*s})

  if ( isDesktop && isOverview && !rmode ) return (
    <>
      {/* {console.log( data )} */}
      <svg style={{width:'100%',height:'100%',position:'absolute',pointerEvents:'none'}}>
        { data?.map(({MAIN,C1,C2,DISABLED})=>(
          <g key={MAIN}>
            <line x1={C1[0]} y1={C1[1]} x2={C2[0]} y2={C2[1]} {...line(DISABLED)}/>
            <circle cx={C1[0]} cy={C1[1]} r={3*s} fill={DISABLED?'#00000080':'#ffffff80'}/>
            <circle cx={C2[0]} cy={C2[1]} r={3*s} fill={DISABLED?'#00000080':'#ffffff80'}/>
          </g>
        )) }
      </svg>
      { data?.map(({MAIN,C2,SUB1,SUB2,ORIGIN,DISABLED})=>(
        <span key={MAIN}>
          <Label 
            origin={ORIGIN} 
            key={MAIN} 
            x={C2[0]} 
            y={C2[1]} 
            style={lab}
            data-id={MAIN}
            // onMouseEnter={(e)=>{ setHover( e.target.dataset.id ) }}
            // onMouseLeave={(_)=>{ setHover( {} ) }}
            // hover={hover===MAIN}
            disabled={DISABLED}
          >
            <Name s={s}>{MAIN}</Name>
            <Sub  s={s}>{SUB1}</Sub>
            <Sub  s={s}>{SUB2}</Sub>
          </Label>
        </span>
      )) }
    </>
  )

  return null

}

