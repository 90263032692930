import { Box, IconButton } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import { toggle_drawer } from "state/actions";
import { useAtom } from "state/jotai";

function TopDrawer({ backgroundColor, textColor, statusText, address }) {
  const toggle = useAtom(toggle_drawer)[1];

  const handleClose = () => {
    toggle("detail");
  };

  return (
    <Box
      sx={{
        backgroundColor,
        color: textColor,
        py: { xs: 1, md: 1.5 },
        px: 2.5,
        height: 59
      }}
    >
      <IconButton
        onClick={handleClose}
        color="inherit"
        size="small"
        sx={{
          position: "absolute",
          right: 18,
          top: 12,
          cursor: "pointer",
        }}
      >
        <Icon icon="close" fontSize="inherit" />
      </IconButton>
      <strong>{statusText}</strong>
      <Box mt={0.5}>{address}</Box>
    </Box>
  );
}

TopDrawer.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  statusText: PropTypes.string,
  address: PropTypes.string,
};

TopDrawer.defaultProps = {
  backgroundColor: "#AAAAAA",
  textColor: "#000000",
  statusText: "Ledig",
  address: "",
};

export default TopDrawer;
