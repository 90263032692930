import styled from 'styled-components/macro'
import ReactSlider from 'react-slider'
import { BREAK_ } from 'constants'

export const Control = styled(ReactSlider)`
  width: 70%;
  height: 25px;
  margin:0 auto;
`
export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position:absolute;
  bottom:135px;
  ${BREAK_.md_dn} {
    bottom:35px;
  }
  background: transparent;
  /* border:5px solid purple; */
`

export const Text = styled.div`
  position: absolute;
  top: -50px;
  left: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  transform: translatex(-50%);
  /* border:1px solid black; */
  width: 100%;
  text-align: center;
`