import styled, { css } from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { MENU } from 'constants'

export const Menu = styled.div`
  width: ${ MENU.DESKTOP_NAV_DRAWER_W }px;
  border-left: 2px solid ${({ clr }) => clr?.icons}45;
  background-color: ${({ clr }) => clr?.primary};
  overflow: scroll;
  ${sb.hide};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  height:100%;
  position:fixed;
  top:0;
`

export const Tile = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ clr }) => clr?.primary_text};
  align-items: center;
  min-width: 0;
  gap: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border:5px solid red; */
`

export const Split = styled.div`
  height: 2px;
  background-color: ${({ clr }) => `${clr?.icons}45`};
  width: calc(100% - 30px);
`

export const Text = styled.div`
  padding-right: 60px;
  white-space: nowrap;
  color : ${({ clr }) => `${clr?.sub_text_color}`};
  /* border: 4px solid pink; */
`