const x = (i,j) => i.Data[j.property]?.UnformattedValue >= j.min
const y = (i,j) => i.Data[j.property]?.UnformattedValue <= j.max
const z = (j,k) => j.Data[k].UnformattedValue === true

export const filter_ms = ( units , arr ) => {
  let temp = units
  return arr?.map( i => {
    temp = temp?.filter( j => i.values .some( k => z(j,k) ) )
  }) ? temp : units
}

export const filter_in = ( units , arr ) => 
  ( arr?.length > 0 ) 
  ? units?.filter( i => arr.every( j => x(i,j) && y(i,j) ) ) 
  : units

export const filter_dd = ( units,  value ) => 
  value
  ? units?.filter( i => i.Data['Boligtype']?.Value == value)
  : units

export const applyfilters = ( units , filters ) => {

  const r0 = units
  const r1 = filter_ms( r0 , filters.multiSelect )
  const r2 = filter_in( r1 , filters.interval )
  const r3 = filter_dd( r2 , filters.dropdown )
  return r3
}

export const remove  = (obj, arr) => [...arr?.filter( i => i.property !== obj.property ) ?? [] ]
export const replace = (obj, arr) => [...arr?.filter( i => i.property !== obj.property ) ?? [], obj ]
export const add     = (obj, arr) => [...arr, obj]

export const format = ( val, possiblevalues, postfix, name ) => {
  
  const idx = possiblevalues?.indexOf(val.toString()) ?? null

  if ( possiblevalues && name == 'Etage')           return `${ numberformat( possiblevalues[val] )}`
  if ( possiblevalues && name == 'VaerelserAntal' ) return `${ numberformat( possiblevalues[idx] )}`
  return `${ numberformat( val ) } ${ postfix }`

}

export const numberformat = num => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') ?? num